import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Layout from '../components/layout'
import Img from 'gatsby-image'
import '../components/memoriam/memoriam.css'
import { Link } from 'gatsby'

const memoriam_template = ({ data }) => 
{
  var memoriam_template = data.strapiMemoriamCollecionTypes;
return (
  <Layout>
    <div
      className="card memoriam_template_card"
      style={{
        backgroundColor: memoriam_template.PageBGColor,
        boxShadow: '5px 10px 18px #888888',
      }}
    >
      <Link to="/memoriam">
      {' '}
      <span style={{ fontSize: '25px' }}>←</span> Back to Memoriam Page
      </Link>
      <br/>
      
      <div
        style={{
          backgroundColor: 'darkgreen',
          padding: '10px',
          color: 'white',
        }}
      >
        <h3 style={{display:"flex"}}>{memoriam_template.Name}</h3>
        <span style={{ fontSize: '20px',float:"right" }}>{memoriam_template.Date}</span>
      </div>
      <div class="center" style={{ padding: '15px' }}>
        <Img fixed={memoriam_template.Image.childImageSharp.fixed} />
      </div>
      <p
        style={{
          lineHeight: '1.6',
          textAlign: 'justify',
          textJustify: 'inter-word',
        }}
      >
        {' '}
        <ReactMarkdown source={memoriam_template.Description} />
      </p>
    </div>
  </Layout>
)
}

export default memoriam_template


export const query = graphql`
  query memoriam_template($Slug: String!){
    strapiMemoriamCollecionTypes(Slug: {eq: $Slug}) {
      Date
      Description
      Name
      Slug
      PageBGColor
      Image {
        childImageSharp {
          fixed(width: 155, height: 185){
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`